/* eslint-disable sort-keys */
export default () => ({
  address1: {
    required: { message: 'Billing Address is required', value: true }
  },
  city: {
    pattern: { message: 'Enter city name using only letters', value: /^[a-zA-Z]/ },
    required: { message: 'City is required', value: true }
  },
  zipcode: {
    pattern: { message: 'Enter a 5 digit Zip code', value: /^\d{5}(?:-\d{4})?$/ },
    required: { message: 'Zip code is required', value: true }
  },
  state: {
    pattern: { message: 'Enter state', value: /^[a-zA-Z]/ },
    required: { message: 'State is required', value: true }
  },
  billingFrequency: {
    required: { message: 'Confirm this address is correct', value: true }
  }
});