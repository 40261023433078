export default (getValues: any) => ({

  'accountNumber': {
    pattern: { message: 'Please enter a number', value: /^\d{1,17}$/ },
    required: { message: 'Account number is required', value: true }
  },
  'confirmAccountNumber': {
    required: { message: 'Account number is required', value: true },
    validate: (value: string) => value === getValues()?.accountNumber || 'Account numbers must match'
  },
  'firstname': {
    required: { message: 'First name is required', value: true }
  },
  'lastname': {
    required: { message: 'Last name is required', value: true }
  },
  'routingNumber': {
    pattern: { message: 'Enter a 9 digit routing number', value: /^\d{9}$/ },
    required: { message: 'Routing number is required', value: true }
  },
  'term0': {
    required: { message: 'Please agree before continuing', value: true }
  },
  'term1': {
    required: { message: 'Please agree before continuing', value: true }
  },
  'term2': {
    required: { message: 'Please agree before continuing', value: true }
  },
  'termDefault': {
    required: { message: 'Please agree before continuing', value: true }
  }
});