/* eslint-disable sort-keys */
/* eslint-disable max-len */
export default () => ({
  firstName: {
    required: { message: 'First name is required', value: true }
  },
  lastName: {
    required: { message: 'Last name is required', value: true }
  },
  phone: {
    pattern: { message: 'Enter a 10 digit Phone number', value: /^[+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/ },
    required: { message: 'Phone is required', value: true }
  },
  email: {
    pattern: { message: 'Please provide a valid e-mail address', value: /^(([^<>()[\]\\.,;:\s@"+]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ },
    required: { message: 'Email is required', value: true }
  },
  address1: {
    required: { message: 'Service Address is required', value: true }
  },
  city: {
    pattern: { message: 'Enter city name using only letters', value: /^[a-zA-Z]/ },
    required: { message: 'City is required', value: true }
  },
  zipcode: {
    required: { message: 'Zip code is required', value: true }
  },
  state: {
    pattern: { message: 'Enter state', value: /^[a-zA-Z]/ },
    required: { message: 'State is required', value: true }
  }
});